import Paragraph from '@/lib/Layout/Wrappers/Paragraph';
import { Button } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import Image from "next/image";
import { useRouter } from 'next/router';
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';

export const ManageDiabetesStep = ({
    idx,
    title,
    paragraph1,
    paragraph2,
    icon,
    stepRef,
    alt
  }: {
    idx: number;
    title: string;
    paragraph1: string;
    paragraph2?: string;
    icon: string;
    stepRef: any,
    alt: string
  }) => {
    const router = useRouter();

    return (
      <motion.li className="flex flex-col justify-center gap-4 w-3/4 h-[107vh]">
        <h6 className="flex gap-4 items-center text-2xl font-semibold" >
          <div ref={stepRef} className="h-12 w-12 bg-black rounded-full flex justify-center items-center text-white">
            0{idx + 1}
          </div>
          {title}
          <Image src={icon} alt={alt} height={30} width={30} />
        </h6>
        <Paragraph className='text-lg'>{paragraph1}</Paragraph>
        <Paragraph className='text-lg'>{paragraph2}</Paragraph>
        <p className="flex gap-4 items-center mt-4 cursor-pointer text-2xl font-semibold">
          <Button variant="unstyled" onClick={() => router.push("/all-products")} fontSize={"lg"}>
          Learn More         
          </Button>
          <HiOutlineArrowNarrowRight size={20} className="text-black mt-1" />
        </p>
      </motion.li>
    );
  }
  