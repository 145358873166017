import Section from "@/lib/Layout/Wrappers/Section";
import React from "react";
import { useRouter } from "next/router";

const BiologicalAgeBanner = () => {
    const router = useRouter();
    return (
        <Section className="mb-32 mt-[-30px] text-black">
            <div className="md:flex md:flex-row hidden">
                <div className="w-[60%] flex flex-col justify-center">
                    <div className="">
                        <h2 className="text-[3.375rem] font-bold mb-5">Your Body's True Age: A Scientific Assessment</h2>
                    </div>
                    <div>
                        <p className="md:mb-20 text-lg md:w-[80%]">Take the quiz to harness the power of science in recalibrating your health and turning back your body's biological clock.</p>
                        <button onClick={() => router.push('/biologicalAgeCalculator')} className="w-[328px] h-[53px] text-white bg-black rounded-lg font-semibold">Discover More</button>
                    </div>
                </div>
                <div className="w-[40%]">
                    <img src="https://storage.googleapis.com/bonatra-assets/Website%20Assets/MOckups.webp" />
                </div>
            </div>
            <div className="flex flex-col md:hidden mt-8">
                <div>
                    <h2 className="text-4xl font-bold mb-5">Your Body's True Age: A Scientific Assessment</h2>
                    <p className="mb-8">Find out how your lifestyle choices reflect your true age.</p>
                </div>
                <img src="https://storage.googleapis.com/bonatra-assets/Website%20Assets/MOckups.webp" />
                <div className="mt-10">
                    <p className="text-lg mb-9">Take the quiz to harness the power of science in recalibrating your health and turning back your body's biological clock.</p>
                    <button onClick={() => router.push('/biologicalAgeCalculator')} className="w-full h-[53px] text-white bg-black rounded-lg font-semibold mb-10">Discover More</button>
                </div>
            </div>
        </Section>
    )
}

export default BiologicalAgeBanner;