import Section from "@/lib/Layout/Wrappers/Section";
import { manageDiabetesData } from "../../pages/api/manageDiabetes";
import Paragraph from "@/lib/Layout/Wrappers/Paragraph";
import Image from "next/image";
import { motion, useInView, AnimatePresence } from "framer-motion";
import { ManageDiabetesStep } from "./ManageDiabetesStep";
import React from "react";

const manageDiabetesImages = [
  "https://assets.bonatra.com/package_detail/Frame%201181_iphone13midnight_portrait.webp",
  "https://assets.bonatra.com/package_detail/Frame%201180_iphone13midnight_portrait.webp",
  "https://assets.bonatra.com/package_detail/Frame%201184_iphone13midnight_portrait.webp",
  "https://assets.bonatra.com/package_detail/Frame%201182_iphone13midnight_portrait.webp",
  "https://assets.bonatra.com/package_detail/deir%20plan%20and%20fitness%20plan%20AND%20(1).webp",
  "https://assets.bonatra.com/package_detail/Frame%201183_iphone13midnight_portrait.webp",
  "https://assets.bonatra.com/package_detail/Frame%201185_iphone13midnight_portrait.webp",
  "https://assets.bonatra.com/package_detail/Dashboard%20AND.webp",
];

const ManageDiabetes = ({
  manageDiabetesData,
}: {
  manageDiabetesData: manageDiabetesData;
}) => {
  // const { scrollY } = useScroll();
  // const y = useTransform(scrollY, [1800, 2200], [400, -250]);

  const step1Ref = React.useRef(null);
  const step2Ref = React.useRef(null);
  const step3Ref = React.useRef(null);
  const step4Ref = React.useRef(null);
  const step5Ref = React.useRef(null);
  const step6Ref = React.useRef(null);
  const step7Ref = React.useRef(null);
  const step8Ref = React.useRef(null);

  const step1InView = useInView(step1Ref);
  const step2InView = useInView(step2Ref);
  const step3InView = useInView(step3Ref);
  const step4InView = useInView(step4Ref);
  const step5InView = useInView(step5Ref);
  const step6InView = useInView(step6Ref);
  const step7InView = useInView(step7Ref);
  const step8InView = useInView(step8Ref);

  const manageDiabetesSteps = manageDiabetesData?.data;
  return (
    <Section className="flex flex-col gap-8 py-12">
      <h5 className="text-center text-6xl font-semibold">
        {manageDiabetesData.heading}
      </h5>
      <Paragraph className="text-center">
        {manageDiabetesData.subHeading}
      </Paragraph>
      <div>
        <motion.div className="sticky h-[500px] w-[280px] top-1/2 -translate-y-[250px] ">
          <AnimatePresence>
            {step1InView && (
              <motion.div
                exit={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                initial={{ opacity: 1 }}
                transition={{ duration: 0.6 }}
              >
                <Image
                  src={manageDiabetesImages[0]}
                  style={{ objectFit: "contain" }}
                  height={300}
                  width={300}
                  alt="screenshot"
                />
              </motion.div>
            )}
            {step2InView && (
              <motion.div
                exit={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                initial={{ opacity: 1 }}
                transition={{ duration: 0.6 }}
              >
                <Image
                  src={manageDiabetesImages[1]}
                  style={{ objectFit: "contain" }}
                  height={300}
                  width={300}
                  alt="screenshot"
                />
              </motion.div>
            )}
            {step3InView && (
              <motion.div
                exit={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                initial={{ opacity: 1 }}
                transition={{ duration: 0.6 }}
              >
                <Image
                  src={manageDiabetesImages[2]}
                  style={{ objectFit: "contain" }}
                  height={300}
                  width={300}
                  alt="screenshot"
                />
              </motion.div>
            )}
            {step4InView && (
              <motion.div
                exit={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                initial={{ opacity: 1 }}
                transition={{ duration: 0.6 }}
              >
                <Image
                  src={manageDiabetesImages[3]}
                  style={{ objectFit: "contain" }}
                  height={300}
                  width={300}
                  alt="screenshot"
                />
              </motion.div>
            )}
            {step5InView && (
              <motion.div
                exit={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                initial={{ opacity: 1 }}
                transition={{ duration: 0.6 }}
              >
                <Image
                  src={manageDiabetesImages[4]}
                  style={{ objectFit: "contain" }}
                  height={300}
                  width={300}
                  alt="screenshot"
                />
              </motion.div>
            )}
            {step6InView && (
              <motion.div
                exit={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                initial={{ opacity: 1 }}
                transition={{ duration: 0.6 }}
              >
                <Image
                  src={manageDiabetesImages[5]}
                  style={{ objectFit: "contain" }}
                  height={300}
                  width={300}
                  alt="screenshot"
                />
              </motion.div>
            )}
            {step7InView && (
              <motion.div
                exit={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                initial={{ opacity: 1 }}
                transition={{ duration: 0.6 }}
              >
                <Image
                  src={manageDiabetesImages[6]}
                  style={{ objectFit: "contain" }}
                  height={300}
                  width={300}
                  alt="screenshot"
                />
              </motion.div>
            )}
            {step8InView && (
              <motion.div
                exit={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                initial={{ opacity: 1 }}
                transition={{ duration: 0.6 }}
              >
                <Image
                  src={manageDiabetesImages[7]}
                  style={{ objectFit: "contain" }}
                  height={300}
                  width={300}
                  alt="screenshot"
                />
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
        <div className="flex flex-col items-end gap-16 -translate-y-96">
          <article className="w-2/3">
            <ul className="">
              {/* {manageDiabetesSteps.map((steps, idx) => (
        <li key={idx} ref={el => (refs.current[idx] = el)} >
          <ManageDiabetesStep idx={idx} {...steps} />
        </li>
      ))} */}

              <ManageDiabetesStep
                stepRef={step1Ref}
                idx={0}
                {...manageDiabetesSteps[0]}
              />
              <ManageDiabetesStep
                stepRef={step2Ref}
                idx={1}
                {...manageDiabetesSteps[1]}
              />
              <ManageDiabetesStep
                stepRef={step3Ref}
                idx={2}
                {...manageDiabetesSteps[2]}
              />
              <ManageDiabetesStep
                stepRef={step4Ref}
                idx={3}
                {...manageDiabetesSteps[3]}
              />
              <ManageDiabetesStep
                stepRef={step5Ref}
                idx={4}
                {...manageDiabetesSteps[4]}
              />
              <ManageDiabetesStep
                stepRef={step6Ref}
                idx={5}
                {...manageDiabetesSteps[5]}
              />
              <ManageDiabetesStep
                stepRef={step7Ref}
                idx={6}
                {...manageDiabetesSteps[6]}
              />
              <ManageDiabetesStep
                stepRef={step8Ref}
                idx={7}
                {...manageDiabetesSteps[7]}
              />
            </ul>
            {/* <MangeDiabetesSteps
              refs={stepRefs}
              manageDiabetesSteps={manageDiabetesData?.data}
            /> */}
          </article>
        </div>
      </div>
    </Section>
  );
};

export default ManageDiabetes;
