import { FormControl, Input, FormErrorMessage, Flex, useToast } from '@chakra-ui/react'
import { useRouter } from 'next/router'
import React, { ReactNode } from 'react'
import { Controller, useForm } from 'react-hook-form'
import axios from 'axios'

const NewHeroSection = () => {
  const router = useRouter();
  const toast = useToast();

    const validateMobileNumber = (value: string) => {
        const regex = /^[0-9\b]+$/;
        if (!value || value.trim() === "") {
          return "Mobile number is required";
        } else if (value.trim().length !== 10) {
          return "Mobile number should be 10 digits long";
        } else if (!regex.test(value)) {
          return "Mobile number should contain only digits";
        }
        return true;
      };

      const onSubmit = async (values: any) => {
        values["email_id"] = "dummy@test.com";
        const urlParams = new URLSearchParams(window.location.search);
        const utmParams = {
          utmSource: urlParams.get('utm_source'),
          utmMedium: urlParams.get('utm_medium'),
          utmCampaign: urlParams.get('utm_campaign'),
          utmTerm: urlParams.get('utm_term'),
          utmContent: urlParams.get('utm_content'),
        };
        values["utmSource"] = utmParams.utmSource;
        values["utmMedium"] = utmParams.utmMedium;
        values["utmCampaign"] = utmParams.utmCampaign;
        values["utmTerm"] = utmParams.utmTerm;
        values["utmContent"] = utmParams.utmTerm;
    
        const newValues = {
          email: values?.email_id,
          mobile: values?.mobile_no,
          name1: values?.name,
        }   
        
          if(values?.name?.length > 2 && values.mobile_no.length === 10){
            try{
              const { data: response } = await axios({
                method: 'post',
                url: `${process.env.NEXT_PUBLIC_API_URL}/api/resource/Webinar Lead`,
                data: newValues
              });
              reset();
             
            }
            catch(error){
              console.log("error in webinar list",error)
            }
          }   
        else{      
          try {       
            const { data: response } = await axios({
              method: 'post',
              url: `${process.env.NEXT_PUBLIC_API_URL}/api/method/bonatra.templates.includes.consultationform.create_lead`,
              data: {
                type: 'Home Page',
                ...values,
            }
            })
            toast({
              title: "Thank you for your interest. Our expert will contact you soon",
              status: "success",
              duration: 3000,
              position: "top",
              isClosable: true,
            });        
            reset();
          }
          catch(error){
          }
        }
        
      }
    

      const {
        handleSubmit,
        control,
        reset,
        formState: { errors },
      } = useForm();

  return (
    <div className='px-8 pt-4 md:px-16'><h1 className='font-semibold text-[3.125rem] text-black text-center'>Enabling Longer, Healthier Lives</h1><p className='text-[1.25rem] font-normal text-black text-center'>Join Bonatra for a science-backed, data-enriched, tech-driven, and expert-guided longevity journey. Start a better future today!</p><form onSubmit={handleSubmit(onSubmit)} id="landing_page_hero_section">
      <Flex justifyContent="center" alignItems="center" gap="8">
        <Controller
          name="mobile_no"
          control={control}
          defaultValue=""
          rules={{ validate: validateMobileNumber }}
          render={({ field }) => (
            <div>
              <>
                <FormControl isInvalid={Boolean(errors.mobile_no)}>
                  <Input
                    style={{
                      width: "16.25rem",
                      height: "3.3125rem",
                      border: "2px solid #D9D9D9", borderRadius: "0.625rem", marginTop: "5px", marginBottom: "5px"
                    }}
                    {...field}
                    id="mobile_no"
                    type="tel"
                    placeholder="Mobile Number"
                    pattern="[0-9]*"
                    className='w-[2.25rem] h-[3.3125rem]' />
                  <FormErrorMessage>{errors?.mobile_no && errors?.mobile_no?.message as ReactNode}</FormErrorMessage>
                </FormControl>
              </>
            </div>
          )} />
        <button className='bg-black text-white w-[8rem] h-[3.3rem] rounded-[0.625rem]'>Enroll Now</button>

      </Flex>
    </form></div>
   
  )
}

export default NewHeroSection