import Section from "@/lib/Layout/Wrappers/Section";
import React from "react";
import Image from "next/image";
import { Show } from "@chakra-ui/react";
import { useRouter } from "next/router";

const BonatraElitePillars = () => {
  const router = useRouter();
  return (
    <Section>
      <div className="flex flex-col md:flex-row justify-center items-center gap-4">
        <div className="w-full md:w-1/2 rounded-[2.5rem] bg-[#A7E7F6] p-8 flex flex-row justify-center items-center">
          <div className="flex flex-col gap-4 w-1/2">
            <h2 className="font-medium text-[1.875rem] text-black">
              CGM & Smart Ring <span className="text-[#ff3856]">X1</span>{" "}
            </h2>
            <p className="text-[1.125rem] font-light text-black">
              Start your AI-supported health journey today.
            </p>
            <div className="flex flex-row gap-4">
              <Image
                src="https://assets.bonatra.com/package_detail/Circle.webp"
                alt=""
                width={300}
                height={250}
              />
            </div>
            <p className="text-[1.5rem] font-light text-black">
              Program Starts at{" "}
              <span className="font-semibold">Rs. 3499/-</span>{" "}
            </p>
            <p className="text-[#464646] text-[0.875rem] font-light">
              (Taxes Extra)
            </p>
            <button
              className="bg-black text-white w-[6.75rem] h-[3.3rem] rounded-[0.625rem]"
              onClick={() => router.replace("/all-products")}
            >
              {" "}
              → Join Now
            </button>
          </div>
          <div className="w-1/2">
            <Image
              src="https://assets.bonatra.com/package_detail/home%20pageimg.webp"
              alt=""
              width={400}
              height={550}
            />
          </div>
        </div>
        <div className="w-full md:w-1/2">
          <Show above="md">
            <div className="grid grid-cols-3 gap-4">
              <div className="w-[9rem] h-[8rem] ml-4">
                <p className="text-black text-[1.6rem] text-light">
                  Bonatra’s Eight Pillars
                </p>
                <p className="text-[1.5rem] font-light text-black">
                  of <span className="text-[#ff385c]">Longevity</span>
                </p>
              </div>
              <div className="elevation-4 w-[10rem] h-[8rem] rounded-[1.3125rem] border-[1px] border-[#F2F2F2] flex flex-col justify-center items-center p-2 shadow-xl">
                <Image
                  src="https://assets.bonatra.com/package_detail/running.gif"
                  alt="My GIF"
                  width={60}
                  height={60}
                />
                <p className="text-center">Physical Activity</p>
              </div>
              <div className="elevation-4 w-[10rem] h-[8rem] rounded-[1.3125rem] border-[1px] border-[#F2F2F2] flex flex-col justify-center items-center p-2 shadow-xl">
                <Image
                  src="https://assets.bonatra.com/package_detail/avocado.gif"
                  alt="My GIF"
                  width={60}
                  height={60}
                />
                <p className="text-center">Nutrition</p>
              </div>
              <div className="elevation-4 w-[10rem] h-[8rem] rounded-[1.3125rem] border-[1px] border-[#F2F2F2] flex flex-col justify-center items-center p-2 shadow-xl">
                <Image
                  src="https://assets.bonatra.com/package_detail/sleep.gif"
                  alt="My GIF"
                  width={60}
                  height={60}
                />
                <p className="text-center">Quality Sleep</p>
              </div>
              <div className="elevation-4 w-[10rem] h-[8rem] rounded-[1.3125rem] border-[1px] border-[#F2F2F2] flex flex-col justify-center items-center p-2 shadow-xl">
                <Image
                  src="https://assets.bonatra.com/package_detail/mood.gif"
                  alt="My GIF"
                  width={60}
                  height={60}
                />
                <p className="text-center">Stress Management</p>
              </div>
              <div className="elevation-4 w-[10rem] h-[8rem] rounded-[1.3125rem] border-[1px] border-[#F2F2F2] flex flex-col justify-center items-center p-2 shadow-xl">
                <Image
                  src="https://assets.bonatra.com/package_detail/red-blood-cell.gif"
                  alt="My GIF"
                  width={60}
                  height={60}
                />
                <p className="text-center">Detoxification</p>
              </div>
              <div className="elevation-4 w-[10rem] h-[8rem] rounded-[1.3125rem] border-[1px] border-[#F2F2F2] flex flex-col justify-center items-center p-2 shadow-xl">
                <Image
                  src="https://assets.bonatra.com/package_detail/maze.gif"
                  alt="My GIF"
                  width={60}
                  height={60}
                />
                <p className="text-center">Cognitive Simulation</p>
              </div>
              <div className="elevation-4 w-[10rem] h-[8rem] rounded-[1.3125rem] border-[1px] border-[#F2F2F2] flex flex-col justify-center items-center p-2 shadow-xl">
                <Image
                  src="https://assets.bonatra.com/package_detail/social-distance.gif"
                  alt="My GIF"
                  width={60}
                  height={60}
                />
                <p className="text-center">Social Engagement</p>
              </div>
              <div className="elevation-4 w-[10rem] h-[8rem] rounded-[1.3125rem] border-[1px] border-[#F2F2F2] flex flex-col justify-center items-center p-2 shadow-xl">
                <Image
                  src="https://assets.bonatra.com/package_detail/optimistic.gif"
                  alt="My GIF"
                  width={60}
                  height={60}
                />
                <p className="text-center">Positive Mindset</p>
              </div>
            </div>
          </Show>
          <Show below="sm">
            <div className="grid grid-cols-3 gap-4">
              <div className="w-[7rem] md:w-[9rem] h-[6rem] md:h-[8rem] mt-2 md:mt-0">
                <p className="text-black text-[1.2rem] md:text-[1.6rem] text-light">
                  Bonatra’s Eight Pillars
                </p>
                <p className="text-[1.2rem] md:text-[1.5rem] font-light text-black">
                  of <span className="text-[#ff385c]">Longevity</span>
                </p>
              </div>
              <div className="elevation-4 w-[8rem] md:w-[10rem] h-[8rem] rounded-[1.3125rem] border-[1px] border-[#F2F2F2] flex flex-col justify-center items-center p-2 shadow-xl">
                <Image
                  src="https://assets.bonatra.com/package_detail/running.gif"
                  alt="My GIF"
                  width={30}
                  height={30}
                />
                <p className="text-center">Physical Activity</p>
              </div>
              <div className="elevation-4 w-[8rem] md:w-[10rem] h-[8rem] rounded-[1.3125rem] border-[1px] border-[#F2F2F2] flex flex-col justify-center items-center p-2 shadow-xl">
                <Image
                  src="https://assets.bonatra.com/package_detail/avocado.gif"
                  alt="My GIF"
                  width={30}
                  height={30}
                />
                <p className="text-center">Nutrition</p>
              </div>
              <div className="elevation-4 w-[8rem] md:w-[10rem] h-[8rem] rounded-[1.3125rem] border-[1px] border-[#F2F2F2] flex flex-col justify-center items-center p-2 shadow-xl">
                <Image
                  src="https://assets.bonatra.com/package_detail/sleep.gif"
                  alt="My GIF"
                  width={30}
                  height={30}
                />
                <p className="text-center">Quality Sleep</p>
              </div>
              <div className="elevation-4 w-[8rem] md:w-[10rem] h-[8rem] rounded-[1.3125rem] border-[1px] border-[#F2F2F2] flex flex-col justify-center items-center p-2 shadow-xl">
                <Image
                  src="https://assets.bonatra.com/package_detail/mood.gif"
                  alt="My GIF"
                  width={30}
                  height={30}
                />
                <p className="text-center">Stress Management</p>
              </div>
              <div className="elevation-4 w-[8rem] md:w-[10rem] h-[8rem] rounded-[1.3125rem] border-[1px] border-[#F2F2F2] flex flex-col justify-center items-center p-2 shadow-xl">
                <Image
                  src="https://assets.bonatra.com/package_detail/red-blood-cell.gif"
                  alt="My GIF"
                  width={30}
                  height={30}
                />
                <p className="text-center">Detoxification</p>
              </div>
              <div className="elevation-4 w-[8rem] md:w-[10rem] h-[8rem] rounded-[1.3125rem] border-[1px] border-[#F2F2F2] flex flex-col justify-center items-center p-2 shadow-xl">
                <Image
                  src="https://assets.bonatra.com/package_detail/maze.gif"
                  alt="My GIF"
                  width={30}
                  height={30}
                />
                <p className="text-center">Cognitive Simulation</p>
              </div>
              <div className="elevation-4 w-[8rem] md:w-[10rem] h-[8rem] rounded-[1.3125rem] border-[1px] border-[#F2F2F2] flex flex-col justify-center items-center p-2 shadow-xl">
                <Image
                  src="https://assets.bonatra.com/package_detail/social-distance.gif"
                  alt="My GIF"
                  width={30}
                  height={30}
                />
                <p className="text-center">Social Engagement</p>
              </div>
              <div className="elevation-4 w-[8rem] md:w-[10rem] h-[8rem] rounded-[1.3125rem] border-[1px] border-[#F2F2F2] flex flex-col justify-center items-center p-2 shadow-xl">
                <Image
                  src="https://assets.bonatra.com/package_detail/optimistic.gif"
                  alt="My GIF"
                  width={30}
                  height={30}
                />
                <p className="text-center">Positive Mindset</p>
              </div>
            </div>
          </Show>
        </div>
      </div>
    </Section>
  );
};

export default BonatraElitePillars;
