import dynamic from "next/dynamic";
import React from "react";
import Meta from "@/components/Meta";
const Features = dynamic(() => import("@/sections/Features/Features"));
const JoinOurProgram = dynamic(
  () => import("@/sections/JoinOurProgram/JoinOurProgram")
);
const IconBand = dynamic(() => import("@/sections/IconBand/IconBand"));
import { joinOurProgramData } from "./api/joinOurProgramData";
const Insights = dynamic(() => import("@/sections/Insights/Insights"));
import { LeadsForm } from "@/sections/LeadsForm/LeadsForm";
const Articles = dynamic(() => import("@/sections/Articles/Articles"));
const Doctors = dynamic(() => import("@/sections/Doctors/Doctors"));
import { featuresData } from "./api/featuresData";
import ManageDiabetes from "@/sections/ManageDiabetes/ManageDiabetes";
const Blogs = dynamic(() => import("@/sections/Blogs/Blogs"));
import { manageDiabetesData } from "./api/manageDiabetes";
import { footerData } from "./api/footerData";
const SafeHands = dynamic(() => import("@/sections/SafeHands/SafeHands"));
const ManageDiabetesMobile = dynamic(
  () => import("@/sections/ManageDiabetes/ManageDiabetesMobile")
);
import { Show } from "@chakra-ui/react";
import LandingPageTestimonials from "@/sections/LandingPageTestimonials/LandingPageTestimonials";
import NewHeroSection from "@/sections/NewHeroSection/NewHeroSection";
import BonatraElitePillars from "@/sections/BonatraElitePillars/BonatraElitePillars";
import ReliveYourLife from "@/sections/ReliveYourLife/ReliveYourLife";
import BiologicalAgeBanner from "@/sections/BiologicalAgeBanner/BiologicalAgeBanner";
import Layout from "@/lib/Layout/Layout";

export default function Home() {
  return (
    <div className="">
      <Meta
        title="Best Holistic Health Care Company | Bonatra"
        description="Bonatra is the leading Holistic Health Care Company offering natural therapies to promote health and well-being. Improve your physical & mental health with Bonatra"
        imgUrl="https://assets.bonatra.com/images/bonatra-raush-logo.webp"
      />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
      />

      <Layout footerData={footerData}>
        <NewHeroSection />
        <BonatraElitePillars />
        <ReliveYourLife />
        <BiologicalAgeBanner />
        <IconBand />
        <Features featuresData={featuresData} />
        <SafeHands />
        <JoinOurProgram joinOurProgramData={joinOurProgramData} />
        <div id="testimonials">
          <LandingPageTestimonials />
        </div>
        <div id="doctor">
          <Doctors />
        </div>
        <Show above="md">
          <ManageDiabetes manageDiabetesData={manageDiabetesData} />
        </Show>
        <Show below="sm">
          <ManageDiabetesMobile
            manageDiabetesData={manageDiabetesData}
            isDiabetes={false}
            isFitness={false}
            cgmJoinOurProgramData={{
              heading: "",
              subHeading: "",
              data: [],
            }}
            cgmProgramsData={{
              heading: "",
              subHeading: "",
              data: [],
            }}
          />
        </Show>
        <Blogs />
        <Insights />
        <Articles />
        <div id="leadsForm">
          <LeadsForm />
        </div>
      </Layout>
    </div>
  );
}
