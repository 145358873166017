import NavBar from "./NavBar/NavBar";
import React, { ReactNode, useState } from "react";
import { footerData } from "../../pages/api/footerData";
import Footer from "./Footer";
import { AnimatePresence } from "framer-motion";
import NavBar2 from "@/components/Navbar2/NavBar2";
import NavMenu2 from "@/components/Navbar2/NavMenu2";
import { navBarConfig } from '../../components/Navbar2/NavBar2';
import { useGoogleOneTapLogin } from "@react-oauth/google";
import axios from "axios";
import useAuthStore from "@/lib/store/useAuthStore";
import useZustandStore from "@/lib/store/useZustandStore";
import useLoginWithOneTap from "@/lib/hooks/useLoginWithOneTap";
import { useToast, Center, Spinner } from "@chakra-ui/react";
import useCheckLogin from "../hooks/useCheckLogin";


interface Props {
  children: ReactNode;
  footerData?: footerData;
  navBarType?: 'new' | 'old'
  navBarConfig?: navBarConfig
  mode?: 'light' | 'start'
}

const Layout: React.FC<Props> = ({ children, footerData, navBarType, navBarConfig, mode }) => {
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const { setUserData, setToken } = useAuthStore((state) => state);
  const setUser = useZustandStore((state) => state.setUser);
  const { mutate: loginWithOneTapMutate } = useLoginWithOneTap();
  const navBurgerState = useZustandStore((state) => state.navBurgerState);
  const navbar = navBarType === "new" ? <div className="z-50 absolute top-0 sticky"><NavBar2 mode={mode ?? "dark"} navBarConfig={navBarConfig} /></div> : <NavBar />
  const { data: isLoggedIn } = useCheckLogin();
  
  const handleLoginProcess = async (res: any) => {
    if (res.data.full_name) {
      toast({
        title: "Logged In",
        description: "Welcome, " + res.data.full_name,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    }
    localStorage.setItem("token", res.data.token);
    setToken(res.data.token);
    localStorage.setItem("user", res.data.full_name);
    let localUserData = await axios.get(
      `${process.env.NEXT_PUBLIC_API_URL
      }/api/method/bonatra.custom.latest_customer_v2?user=${localStorage.getItem(
        "email",
      )}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        withCredentials: true,
      },
    );
    setUserData(localUserData?.data?.message);
    setUser(res?.data?.message?.data?.user);
    setIsLoading(false);
  };

  useGoogleOneTapLogin({
    onSuccess: async credentialResponse => {
      setIsLoading(true);
      let payload = {
        "client_id": credentialResponse?.clientId,
        "credential": credentialResponse?.credential,
        "select_by": credentialResponse?.select_by,
        "use_jwt": 1
      }
      if (credentialResponse?.credential) {
        loginWithOneTapMutate(
          { payload: payload },
          {
            onSuccess: async (res: any) => {
              if (res?.data?.message?.signup) {
                loginWithOneTapMutate(
                  { payload: payload },
                  {
                    onSuccess: async (res: any) => {
                      handleLoginProcess(res);
                    },
                    onError: (e) => {
                      setIsLoading(false);
                      toast({
                        title: "Error",
                        description:
                          "An error occurred while signing in with Google, please try later",
                        status: "error",
                        duration: 2000,
                        isClosable: true,
                        position: "top",
                      });
                    },
                  });
                return;
              }
              handleLoginProcess(res);
              setIsLoading(false);
            },
            onError: (error: any) => {
              setIsLoading(false);
              if (error.response.status == 409) {
                toast({
                  title: "Error",
                  description: "Please sign up first",
                  status: "error",
                  duration: 2000,
                  isClosable: true,
                  position: "top",
                });
                return;
              }
              toast({
                title: "Error",
                description:
                  "An error occurred while signing in with Google, please try later",
                status: "error",
                duration: 2000,
                isClosable: true,
                position: "top",
              });
            },
          });
      }
    },
    onError: () => {
      setIsLoading(false);
      toast({
        title: "Error",
        description: "Login Failed",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top",
      })
    },
    disabled: isLoggedIn
    // auto_select: true
  });

  if(isLoading) {
    return (
      <Center>
        <Spinner emptyColor="gray.200" color="#ff385c" size="xl" />
      </Center>
    );
  }

  return (
    <>
      {navbar}
      <AnimatePresence> {navBurgerState && <NavMenu2 />}</AnimatePresence>
      {children}
      {footerData && <Footer footerData={footerData} />}
    </>
  );
};

export default Layout;