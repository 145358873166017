// Next.js API route support: https://nextjs.org/docs/api-routes/introduction
import type { NextApiRequest, NextApiResponse } from 'next'


export type joinOurProgramData = {
    data: allNumberData;
    heading: string;
    paragraph: string;
}
export type allNumberData = typeof allNumberData
export type numberData = typeof allNumberData[0]

const allNumberData = [{
    number: 500,
    preText: '',
    postText: '+',
    subText: '',
    text: "Partner Doctors",
},
{
    number: 5,
    preText: '',
    subText: '',
    postText: 'kg ',
    text: "Weight Reduced on avg",
},
{
    number: 99,
    preText: '',
    subText: '',
    postText: '% ',
    text: "Reduced medication",
},
{
    number: 5000,
    preText: '',
    subText: '',
    postText: '+',
    text: "Success Stories",
}]

export const joinOurProgramData = {
    data: allNumberData,
    heading: 'Trust the Best',
    paragraph: "Thousands of people have trusted Bonatra with their healthcare needs and seen results"
}

export default function handler(
    req: NextApiRequest,
    res: NextApiResponse<joinOurProgramData>
) {
    res.status(200).json({ data: allNumberData, heading: 'Join our program', paragraph: "Reverse your Diabetes, PCOD, Weight Issues, etc, with Bonatra's doctor-led IoMT unified personalised programs. Thousands of people have trusted Bonatra with their healthcare needs and we take pride in providing the best service" })
}

