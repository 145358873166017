import Section from "@/lib/Layout/Wrappers/Section";
import React from "react";
import Image from "next/image";
import { useRouter } from "next/router";
import { Show } from "@chakra-ui/react";

const ReliveYourLife = () => {
  const router = useRouter();
  return (
    <Section>
      <h2 className="text-[3.375rem] text-black font-medium">
        Reclaim your health, Reclaim your life!
      </h2>
      <p className="text-[1.5rem] font-light text-black">
        Choose from the range of AI-supported, Doctor led programs.
      </p>
      <div className="flex flex-col md:flex-row  justify-between items-center py-6 md:py-12">
        <div className="w-[26rem] md:w-[28rem] h-[28rem] rounded-[2.5rem] border-[1px] border-[#F3F3F3] elevation-4 bg-[#D94A64] p-8 flex flex-col gap-2 relative">
          <h5 className="text-white font-medium text-[1.875rem]">Age Reversal</h5>
          <p className="text-[1.125rem] text-white font-normal">
            Clinically designed to narrow the age gap between Biological Age and Chronological Age.					
          </p>
          <div className="flex flex-row gap-4">
            <div>
              <Show above="md">
                <Image
                  src="https://assets.bonatra.com/Website%20Assets/Agereversal.webp"
                  alt=""
                  width={170}
                  height={300}
                />
              </Show>
              <Show below="sm">
                <Image
                  src="https://assets.bonatra.com/Website%20Assets/Agereversal.webp"
                  alt=""
                  width={158}
                  height={310}
                />
              </Show>
            </div>
            <div className="absolute bottom-4 right-4">
              <p className="text-[0.9rem] font-light text-white">
                Program Starts From
              </p>
              <p className="text-white text-[1.25rem] font-light">
                Rs. <span className="font-semibold">3499/-</span>
              </p>
              <p className="text-white text-[0.875rem] font-light">
                (Taxes Extra)
              </p>
              <div className="flex flex-row justify-between items-center">
                <button
                  className="bg-black text-white w-[6.75rem] h-[3.3rem] rounded-[0.625rem] my-4"
                  onClick={() => router.replace("/package/age-reversal")}
                >
                  {" "}
                  → Buy Now
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-row justify-between items-center py-6 md:py-12">
          <div className="w-[26rem] md:w-[28rem] h-[28rem] rounded-[2.5rem] border-[1px] border-[#F3F3F3] elevation-4 bg-[#CA9462] p-8 flex flex-col gap-2 relative">
            <h5 className="text-white font-medium text-[1.875rem]">Metabolic Health</h5>
            <p className="text-[1.125rem] text-white font-normal">
              Science backed plan to reverse insulin resistance, manage Diabetes, PCOS & Fatty Liver.
            </p>
            <div className="flex flex-row gap-4">
              <div>
                <Show above="md">
                  <Image
                    src="https://assets.bonatra.com/package_detail/Section%202%20Diabetes%20.webp"
                    alt=""
                    width={178}
                    height={170}
                  />
                </Show>
                <Show below="sm">
                  <Image
                    src="https://assets.bonatra.com/package_detail/Section%202%20Diabetes%20.webp"
                    alt=""
                    width={165}
                    height={310}
                  />
                </Show>
              </div>
              <div className="absolute bottom-4 right-4">
                <p className="text-[0.9rem] font-light text-white">
                  Program Starts From
                </p>
                <p className="text-white text-[1.25rem] font-light">
                  Rs. <span className="font-semibold">3499/-</span>
                </p>
                <p className="text-white text-[0.875rem] font-light">
                  (Taxes Extra)
                </p>
                <div className="flex flex-row justify-between items-center my-4">
                  <button
                    className="bg-black text-white w-[6.75rem] h-[3.3rem] rounded-[0.625rem]"
                    onClick={() => router.replace("/package/metabolic-health")}
                  >
                    {" "}
                    → Buy Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-row justify-between items-center py-6 md:py-12">
          <div className="w-[26rem] md:w-[28rem] h-[28rem] rounded-[2.5rem] border-[1px] border-[#F3F3F3] elevation-4 bg-[#3FB8AF] p-8 flex flex-col gap-2 relative">
            <h5 className="text-white font-medium text-[1.875rem]">
              Weight Loss
            </h5>
            <p className="text-[1.125rem] text-white font-normal">
              Guaranteed results with Bonatra’s Doctor-led, IOT driven, science backed weight loss program.					
            </p>
            <div className="flex flex-row gap-4">
              <div>
                <Show above="md">
                  <Image
                    src="https://assets.bonatra.com/Website%20Assets/Weightmanagement.webp"
                    alt=""
                    width={167}
                    height={200}
                  />
                </Show>
                <Show below="sm">
                  <Image
                    src="https://assets.bonatra.com/Website%20Assets/Weightmanagement.webp"
                    alt=""
                    width={155}
                    height={180}
                  />
                </Show>
              </div>
              <div className="absolute bottom-4 right-4">
                <p className="text-[0.9rem] font-light text-white">
                  Program Starts From
                </p>
                <p className="text-white text-[1.25rem] font-light">
                  Rs. <span className="font-semibold">3499/-</span>
                </p>
                <p className="text-white text-[0.875rem] font-light">
                  (Taxes Extra)
                </p>
                <div className="flex flex-row justify-between items-center">
                  <button
                    className="bg-black text-white w-[6.75rem] h-[3.3rem] rounded-[0.625rem] my-4"
                    onClick={() => router.replace("/package/weight-loss")}
                  >
                    {" "}
                    → Buy Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default ReliveYourLife;
